.Testimonial {
  float: left;
  display: block;
  width: 100%;
  padding: 50px 0;
  background-color: #0d77bd;
  border-top: 3px solid #22a6de;  
  color: #fff;
  font-family: 'Playfair Display', serif;
  text-align: center;

  img {
      margin-top: 5px;
  }

  h2 {
      font-weight: normal;
      font-size: 50px;
      line-height: 85px;
      position: relative;
      display: unset;

      @media screen and (max-width: 650px) {
        font-size: 40px;
      }
  }

  h2:before {
      content: url(../../assets/images/testimonial.png);
      position: absolute;
      top: 0;
      left: -80px;

      @media screen and (max-width: 650px) {
        display: none;
    }
  }

  .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
      opacity: 0;
  }

  .slick-prev {
      left: 8%;
      width: 65px;
      height: 40px;
      z-index: 9;
      transform: rotate(180deg);

      @media screen and (max-width: 900px) {
        left: 3%;
      }

      @media screen and (max-width: 769px) {
          display: none !important;
      }
  }

  .slick-prev:before {
      content: '\279C';
      color: #fff;
      font-size: 65px;
      opacity: 1;
  }

  .slick-next {
      right: 8%;
      width: 65px;
      height: 65px;

      @media screen and (max-width: 900px) {
        right: 3%;
      }

      @media screen and (max-width: 769px) {
          display: none !important;
      }
  }

  .slick-next:before {
      content: '\279C';
      color: #fff;
      font-size: 65px;
      opacity: 1;
  }
  .testimonialSlider {
      width: 100%;
    //   min-height: 150px;

      .testimonialSliderContent {
        width: 78%;
        margin: 0 auto;
        padding: 15px 0;

        @media screen and (max-width: 769px) {
            width: 100%;
        }

        p {
            font-size: 18px;
            line-height: 30px;

            @media screen and (max-width: 1400px) {
              font-size: 15px;
            }
        }

        p#quote-company {
            font-weight: bold;
            margin-top: 12px;
            font-size: 20px;
        }
      }
  }
}