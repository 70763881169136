.serviceOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
}

.Service {
    padding: 7.5vh 0;
    position: relative;
    border-top: 3px solid #22a6de;
    background-color: #f3f3f3;
    height: 100vh;

    @media screen and (max-width: 1200px) {
        padding: 60px 0;
        height: auto;
    }

    .servicePopupContainer {
        top: 22vh;
        position: absolute;
        width: 60%;
        display: block;
        left: 0;
        right: 0;
        margin: 0 auto;
        min-height: 60vh;
        max-height: 60vh;
        background-color: #fff;
        overflow-y: scroll;
        z-index: 101;

        @media screen and (max-width: 1200px) {
            top: 140px;
            min-height: 380px;
            max-height: 380px;
        }

        .servicePopup {
            position: relative;
            padding: 3%;

            button {
                position: absolute;
                top: 0;
                right: 1%;
                transition: all 200ms;
                font-size: 30px;
                font-weight: bold;
                text-decoration: none;
                color: #333;
                background-color: transparent;
                border: none;

                &:focus {
                    outline: none;
                }

                &:hover {
                    color: #22a4dd;
                }
            }
        }
    }

    .slicks-prev, .slicks-next {
        // font-size: 0;
        line-height: 0;
        position: absolute;
        /* top: 50%; */
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }

    .slicks-prev.slicks-disabled:before, .slicks-next.slicks-disabled:before {
        opacity: 0;
    }

    .slicks-prev {
        left: -45%;
        top: 100%;
        width: 20px;
        height: 50px;
        z-index: 9;
        // transform: rotate(180deg);

        // @media screen and (max-width: 1000px) {
        //     top: 45%;
        // }

        // @media screen and (max-width: 769px) {
        //     display: none !important;
        // }
    }

    .slicks-prev:before {
        content: '\2039';
        color: #0d77bd;
        font-size: 50px;
    }

    .slicks-next {
        right: -45%;
        top: 100%;
        width: 20px;
        height: 50px;

        // @media screen and (max-width: 1000px) {
        //     top: 45%;
        // }

        // @media screen and (max-width: 769px) {
        //     display: none !important;
        // }
    }

    .slicks-next:before {
        content: '\203A';
        color: #000;
        font-size: 50px;
    }

    .videoContainer {
        position: relative;

        .sliderVideoIndex {
            position: absolute;
            bottom: -15%;
            left: 45%;
            font-size: 25px;
            line-height: 16px;
            color: #238ccc;
            font-family: 'Playfair Display', serif;
            font-weight: 600;
    
            .sliderIndexContent {
                width: 100%;
                position: relative;
    
                p {
                    display: inline;
                }
            }
        }
    }

    .sliderIndex {
        position: absolute;
        bottom: 8.5%;
        left: 45%;
        font-size: 25px;
        line-height: 16px;
        color: #238ccc;
        font-family: 'Playfair Display', serif;
        font-weight: 600;

        @media screen and (max-width: 900px) {
            bottom: 3%;
            left: 42%;
        }

        // @media screen and (max-width: 650px) {
        //     bottom: 3%;
        //     left: 42%;
        // }

        .sliderIndexContent {
            width: 100%;
            position: relative;

            p {
                display: inline;
            }
        }
    }

    .serviceNav {
        text-align: center;
        width: 100%;
        margin: 0 auto;
    }

    .serviceNavItem {
            position: relative;
            border-radius: 15px;
            display: inline-block;
            padding: 25px 0;
            width: 75px;
            background: rgb(149,150,151);
            background: linear-gradient(180deg, rgba(149,150,151,1) 0%, rgba(132,132,134,1) 100%);
            margin: 0 1%;
            color: #fff;
            cursor: pointer;
            z-index: 99;

            #who, #what, #how {
                content: '';
                height: 3px;
                background-color: #22a4dd;
                width: 75px;
                position: absolute;
                bottom: -15px;
                left: 0;
                display: block;
                transition: 1s ease;
            }
    }

    #li-who:hover #who {
        opacity: 1 !important;
    }

    #li-what:hover #what {
        opacity: 1 !important;
    }

    #li-how:hover #how {
        opacity: 1 !important;
    }

    // .serviceNavItem:after {
    //     content: '';
    //     height: 3px;
    //     background-color: #22a4dd;
    //     width: 75px;
    //     position: absolute;
    //     bottom: -15px;
    //     left: 0;
    //     display: none;
    // }

    .serviceNavItem:nth-child(2n-1) {
        background: rgb(32,150,211);
        background: linear-gradient(180deg, rgba(32,150,211,1) 0%, rgba(36,137,202,1) 100%);
    }

    .serviceContent {
        width: 84%;
        float: left;
        margin: 1vh 0;
        position: absolute;
        z-index: 0;

        @media screen and (max-width: 1025px) {
            margin: 2vh 0;
        }

        .serviceContentLeft {
            width: 32%;
            float: left;
            background-image: url(../../assets/images/about-bg.png);
            background-repeat: no-repeat;
            position: relative;
            height: 74.5vh;

            @media screen and (max-width: 1200px) {
                height: auto;
            }

            @media screen and (max-width: 1025px) {
                width: 35%;
            }

            @media screen and (max-width: 900px) {
                width: 100%;
                height: 100vh;
            }

            // @media screen and (max-width: 769px) {
            //     width: 45%;
            // }

            // @media screen and (max-width: 650px) {
            //     width: 100%;
            // }

            h2 {
                color: #22a4dd;
                font-size: 175px;
                line-height: 16px;
                font-family: 'Playfair Display', serif;
                // margin-bottom: 200px;
                margin-top: 8.5vh;
                // width: 26.3%;
                position: absolute;
                left: -1000px;
                transition: 0.7s ease;

                @media screen and (max-width: 1566px) {
                    font-size: 155px;
                }

                @media screen and (max-width: 1440px) {
                    font-size: 140px;
                }

                @media screen and (max-width: 1200px) {
                    margin-top: 50px;
                }

                @media screen and (max-width: 1025px) {
                    font-size: 100px;
                }

                @media screen and (max-width: 900px) {
                    // font-size: 80px;
                    font-size: 55px;
                }

                // @media screen and (max-width: 650px) {
                //     font-size: 55px;
                // }
            }

            h4 {
                font-size: 60px;
                line-height: 62px;
                width: 75%;
                color: #6d6f72;
                font-family: 'Playfair Display', serif;
                font-weight: normal;
                // margin-bottom: 85px;
                min-height: 14vh;
                position: absolute;
                left: -1000px;
                top: 29.5vh;
                transition: 1s ease;
                text-align: center;

                @media screen and (max-width: 1440px) {
                    font-size: 50px;
                    line-height: 55px;
                }

                @media screen and (max-width: 1200px) {
                    top: 170px;
                }

                @media screen and (max-width: 1025px) {
                    font-size: 40px;
                    line-height: 45px;
                }

                @media screen and (max-width: 900px) {
                    font-size: 60px;
                    list-style: 62px;
                    list-style: 62px;
                    bottom: -55px;
                    right: 0 !important;
                    top: unset;
                    left: unset !important;
                    width: 48%;
                }

                @media screen and (max-width: 820px) {
                    bottom: -85px;
                }

                @media screen and (max-width: 700px) {
                    bottom: -175px;
                    width: 55%;
                }

                @media screen and (max-width: 600px) {
                    bottom: -255px;
                    width: 65%;
                }

                @media screen and (max-width: 500px) {
                    font-size: 40px;
                    line-height: 40px;
                    width: 80%;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }

                // @media screen and (max-width: 650px) {
                //     font-size: 60px;
                //     line-height: 62px;
                // }
            }

            .serviceImage {
                width: 75%;
                position: absolute;
                top: 59vh;
                left: 0;
                height: 15.5vh;
                transition: 1s ease;

                @media screen and (max-width: 1200px) {
                    top: 365px;
                    height: 85px;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }

                // @media screen and (max-width: 769px) {
                //     width: 100%;
                // }

                // @media screen and (max-width: 650px) {
                //     display: none;
                // }

                img {
                    width: 100%;
                    height: 15.5vh;

                    @media screen and (max-width: 1200px) {
                        height: 85px;
                    }
                }
            }
        }

        .serviceContentRight {
            // position: absolute;
            // right: 15%;
            // top: 185px;
            // z-index: 0;
            width: 25%;
            float: right;
            margin-right: 8%;
            transition: 1s ease;

            @media screen and (max-width: 1566px) {
                width: 27%;
                margin-right: 6%;
            }

            @media screen and (max-width: 1200px) {
                width: 29%;
                margin-right: 4%;
                margin-top: 30px;
            }
    
            @media screen and (max-width: 1025px) {
                width: 33%;
            }

            @media screen and (max-width: 900px) {
                width: 84%;
            }

            // @media screen and (max-width: 769px) {
            //     width: 45%;
            // }

            // @media screen and (max-width: 650px) {
            //     width: 84%;
            // }
    
            .serviceImageRight {
                width: 90%;
                position: relative;
                transition: 1s ease;
                height: 59vh;

                @media screen and (max-width: 1566px) {
                    height: 450px;
                }

                @media screen and (max-width: 1200px) {
                    height: 375px;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }

                // @media screen and (max-width: 650px) {
                //     display: none;
                // }
    
                .imgOverlay {
                    position: absolute;
                    width: 14vh;
                    height: 12vh;
                    background-color: #238ccc;
                    right: -12%;
                    bottom: -7%;
                    z-index: -1;
                    transition: 2.2s ease;

                    @media screen and (max-width: 1200px) {
                        height: 75px;
                    }
                }
    
                img {
                    width: 100%;
                    height: 59vh;
                    transition: 1s ease;
                    position: absolute;
                    right: 0;
                    top: 0;

                    @media screen and (max-width: 1566px) {
                        height: 450px;
                    }

                    @media screen and (max-width: 1200px) {
                        height: 375px;
                    }

                    @media screen and (max-width: 769px) {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .serviceFloatingContainer {
        display: block;
        width: 50%;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 75px;
        transition: 1s ease;
        position: relative;

        @media screen and (max-width: 1200px) {
            width: 60%;
            margin-bottom: 0;
            margin-top: 60px;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
        }

        // @media screen and (max-width: 767px) {
        //     width: 70%;
        // }

        // @media screen and (max-width: 650px) {
        //     width: 90%;
        // }

        .contentOverlay {
            display: none;

            @media screen and (max-width: 900px) {
                display: block;
                position: absolute;
                width: 50px;
                height: 50px;
                background-color: #238ccc;
                right: -1.5%;
                bottom: -12px;
                // z-index: -1;
            }
        }

        .serviceVideo {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // position: absolute;
            // top: 180px;
            // left: 26%;
            // width: 50%;
            // position: relative;
            // display: block;
            // width: 100%;
            // margin: 0 auto;
            // margin-top: 100px;
            text-align: left;
            // max-height: 55vh;
            // min-height: 55vh;
            // overflow-y: scroll;
            // -webkit-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // -moz-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // background-color: rgba(256,256,256,0.9);
            // -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            // -o-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            // box-shadow: 0 30px 60px rgba(0, 0, 0, .08);

            // @media screen and (max-width: 650px) {
            //     background-color: rgba(256,256,256,0.7);
            // }

            // video {
            //     width: 100%;
            //     height: 100%;
            // }

            .video-react .video-react-big-play-button.video-react-big-play-button-center {
                margin-top: -1.5em;
                margin-left: -1.5em;
            }

            .video-react .video-react-big-play-button {
                font-size: 3em;
                line-height: 2.5em;
                height: 2.5em;
                width: 2.5em;
                border: 1px solid #fff;
                background-color: transparent;
                border-radius: 2em;
            }
        }

        .caseFloating {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // position: absolute;
            // top: 180px;
            // left: 26%;
            // width: 50%;
            position: relative;
            display: block;
            width: 100%;
            // margin: 0 auto;
            // margin-top: 100px;
            text-align: left;
            max-height: 55vh;
            min-height: 55vh;
            height: auto;
            // overflow-y: scroll;
            // -webkit-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // -moz-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            background-color: rgba(256,256,256,0.9);
            -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            -o-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            box-shadow: 0 30px 60px rgba(0, 0, 0, .08);

            @media screen and (max-width: 1200px) {
                max-height: 355px;
                min-height: 355px;
            }

            @media screen and (max-width: 900px) {
                background-color: rgba(256,256,256,0.7);
                max-height: unset;
                min-height: unset;
                overflow-y: unset;
            }

            // @media screen and (max-width: 650px) {
            //     background-color: rgba(256,256,256,0.7);
            //     max-height: unset;
            //     min-height: unset;
            //     overflow-y: unset;
            // }

            video {
                width: 100%;
                height: 100%;
            }

            .caseFloatingContent {
                max-height: 45vh;
                overflow-y: scroll;
                padding: 25px 45px;

                @media screen and (max-width: 1200px) {
                    margin-top: 75px;
                    max-height: 290px;
                }

                @media screen and (max-width: 900px) {
                    background-color: rgba(256,256,256,0.7);
                    max-height: unset;
                    min-height: unset;
                    overflow-y: unset;
                }

                // @media screen and (max-width: 650px) {
                //     padding: 15px 10px;
                //     padding-bottom: 50px;
                //     max-height: unset;
                //     min-height: unset;
                //     overflow-y: unset;
                // }

                h2 {
                    line-height: 1.3em;
                }

                p {
                    color: #616161;
                    font-size: 20px;
                    line-height: 40px;
                    margin: 0;
                    margin-bottom: 15px;

                    @media screen and (max-width: 1400px) {
                        font-size: 15px;
                        line-height: 25px;
                        -webkit-text-size-adjust: 100%;
                        // padding: 15px 10px;
                    }
    
                    // @media screen and (max-width: 650px) {
                    //     font-size: 15px;
                    //     line-height: 25px;
                    //     // padding: 15px 10px;
                    // }
                }

                p:first-child {
                    margin: 0;
                }

                .case {
                    img {
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        margin-top: 50px;
                        width: 15%;
                        height: auto;

                        @media screen and (max-width: 1566px) {
                            // height: 120px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .serviceFloating {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // position: absolute;
            // top: 180px;
            // left: 26%;
            // width: 50%;
            position: relative;
            display: block;
            width: 100%;
            // margin: 0 auto;
            // margin-top: 100px;
            text-align: left;
            max-height: 55vh;
            min-height: 55vh;
            height: auto;
            // overflow-y: scroll;
            // -webkit-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // -moz-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            background-color: rgba(256,256,256,0.9);
            -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            -o-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            box-shadow: 0 30px 60px rgba(0, 0, 0, .08);

            @media screen and (max-width: 1200px) {
                max-height: 355px;
                min-height: 355px;
            }

            @media screen and (max-width: 900px) {
                background-color: rgba(256,256,256,0.7);
                max-height: unset;
                min-height: unset;
                overflow-y: unset;
            }

            // @media screen and (max-width: 650px) {
            //     background-color: rgba(256,256,256,0.7);
            //     max-height: unset;
            //     min-height: unset;
            //     overflow-y: unset;
            // }

            video {
                width: 100%;
                height: 100%;
            }

            .serviceFloatingContent {
                max-height: 45vh;
                overflow-y: scroll;
                padding: 25px 45px;

                @media screen and (max-width: 1200px) {
                    margin-top: 75px;
                    max-height: 290px;
                }

                @media screen and (max-width: 900px) {
                    padding: 15px 10px;
                    padding-bottom: 60px;
                    max-height: unset;
                    min-height: unset;
                    overflow-y: unset;
                }

                // @media screen and (max-width: 650px) {
                //     padding: 15px 10px;
                //     padding-bottom: 60px;
                //     max-height: unset;
                //     min-height: unset;
                //     overflow-y: unset;
                // }

                h2 {
                    line-height: 1.3em;
                }

                p {
                    color: #616161;
                    font-size: 20px;
                    line-height: 40px;
                    margin: 0;
                    margin-bottom: 15px;

                    @media screen and (max-width: 1400px) {
                        font-size: 15px;
                        line-height: 25px;
                        -webkit-text-size-adjust: 100%;
                        // padding: 15px 10px;
                    }
    
                    // @media screen and (max-width: 650px) {
                    //     font-size: 15px;
                    //     line-height: 25px;
                    //     // padding: 15px 10px;
                    // }
                }
                .case {
                    img {
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        margin-top: 50px;
                        width: 15%;
                        height: auto;

                        @media screen and (max-width: 1566px) {
                            // height: 120px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }

    .serviceFloatingContent::-webkit-scrollbar, .servicePopupContainer::-webkit-scrollbar, .caseFloatingContent::-webkit-scrollbar {
        width: 3px;
    }
    
    .serviceFloatingContent::-webkit-scrollbar-track, .servicePopupContainer::-webkit-scrollbar-track, .caseFloatingContent::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: transparent;
    }
    
    .serviceFloatingContent::-webkit-scrollbar-thumb, .servicePopupContainer::-webkit-scrollbar-thumb, .caseFloatingContent::-webkit-scrollbar-thumb {
        background-color: #22a4dd;
        padding-right: 5%;
    }

    .serviceButton {
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        position: absolute;
        right: 7%;
        top: 50%;

        @media screen and (max-width: 900px) {
            display: none;
        }

        // @media screen and (max-width: 769px) {
        //     right: 5%;
        // }

        // @media screen and (max-width: 650px) {
        //     display: none;
        // }
    }

    .serviceButton:before {
        content: '\279C';
        color: #22a4dd;
        font-size: 65px;
    }
}