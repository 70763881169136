.Talk {
    padding: 5vh 0;
    border-top: 3px solid #22a6de;
    background-image: url(../../assets/images/talk-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    box-sizing: border-box;
    height: 100vh;
    text-align: center;

    @media screen and (max-width: 1200px) {
        height: auto;
    }

    .talkMenuItems {
        width: 20.5%;
        margin: 0 auto;

        @media screen and (min-width: 1921px) {
            width: 14.5%;
        }

        @media screen and (min-width: 2721px) {
            width: 10.5%;
        }

        @media screen and (max-width: 1338px) {
            width: 23.5%;
        }

        @media screen and (max-width: 1200px) {
            width: 32.5%;
        }

        @media screen and (max-width: 849px) {
            width: 46.5%;
        }

        @media screen and (max-width: 590px) {
            width: 66.5%;
        }

        @media screen and (max-width: 413px) {
            width: 95.5%;
        }

        li {
            display: inline-block;
            padding: 25px 0;
            width: 75px;
            background-color: #abacac;
            border-radius: 15px;
            margin: 3%;
            color: #fff;
            font-size: 15px;
            cursor: pointer;

            @media screen and (max-width: 1566px) {
                font-size: 14px;
            }
        }

        li:nth-child(1) {
            background: rgb(176,177,177);
            background: linear-gradient(180deg, rgba(176,177,177,1) 0%, rgba(156,157,158,1) 50%);
        }

        li:nth-child(3) {
            background: rgb(176,177,177);
            background: linear-gradient(180deg, rgba(176,177,177,1) 0%, rgba(156,157,158,1) 50%);
        }

        li:nth-child(5) {
            background: rgb(149,150,151);
            background: linear-gradient(180deg, rgba(149,150,151,1) 0%, rgba(132,132,134,1) 100%);
        }

        li:nth-child(7) {
            background: rgb(125,126,129);
            background: linear-gradient(180deg, rgba(125,126,129,1) 0%, rgba(109,111,114,1) 100%);
        }

        li:nth-child(9) {
            background: rgb(125,126,129);
            background: linear-gradient(180deg, rgba(125,126,129,1) 0%, rgba(109,111,114,1) 100%);
        }

        li:nth-child(2) {
            background: rgb(35,169,225);
            background: linear-gradient(180deg, rgba(35,169,225,1) 0%, rgba(31,156,215,1) 100%);
        }

        li:nth-child(4) {
            background: rgb(32,150,211);
            background: linear-gradient(180deg, rgba(32,150,211,1) 0%, rgba(36,137,202,1) 100%);
        }

        li:nth-child(6) {
            background: rgb(32,150,211);
            background: linear-gradient(180deg, rgba(32,150,211,1) 0%, rgba(36,137,202,1) 100%);
        }

        li:nth-child(8) {
            background: rgb(29,131,198);
            background: linear-gradient(180deg, rgba(29,131,198,1) 0%, rgba(13,119,189,1) 100%);
        }
    }

    .talkContent {
        width: 100%;
        text-align: center;
        margin-top: 7vh;

        h2 {
            font-size: 45px;
            line-height: 10px;
            color: #6f7073;
            font-family: 'Playfair Display', serif;
            font-weight: normal;

            @media screen and (max-width: 1567px) {
                font-size: 40px;
            }

            @media screen and (max-width: 769px) {
                font-size: 35px;
            }

            @media screen and (max-width: 500px) {
                font-size: 25px;
            }

            @media screen and (max-width: 400px) {
                font-size: 18px;
            }

            span {
                color: #0e79be;
                font-size: 60px;
                line-height: 100px;
                font-weight: bold;
                display: block;

                @media screen and (max-width: 1567px) {
                    font-size: 50px;
                    line-height: 80px;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 50px;
                    line-height: 90px;
                    margin-top: 0;
                }

                @media screen and (max-width: 769px) {
                    font-size: 40px;
                    line-height: 70px;
                }

                @media screen and (max-width: 500px) {
                    font-size: 30px;
                    line-height: 55px;
                }

                @media screen and (max-width: 400px) {
                    font-size: 22px;
                    line-height: 40px;
                }
            }
        }
    }

    .talkImage {
        width: 25%;
        margin: 0 auto;
        margin-top: 9vh;

        @media screen and (max-width: 1200px) {
            width: 32%;
            margin-top: 7vh;
        }

        @media screen and (max-width: 769px) {
            width: 42%;
        }

        @media screen and (max-width: 500px) {
            width: 52%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}