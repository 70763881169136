.Footer {
    width: 100%;
    float: left;
    display: block;
    background-color: #00548b;
    color: #fff;
    padding: 30px 0;
    text-align: center;
    font-size: 18px;

    @media screen and (max-width: 1200px) {
        font-size: 15px;
    }

    @media screen and (max-width: 650px) {
        font-size: 13px;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}