$m-device: 1440px;
.Banner {
    margin: 0;
    // border-bottom: 3px solid #22a6de;

    .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
        opacity: 0;
    }

    .slick-prev {
        left: 8%;
        width: 65px;
        height: 40px;
        z-index: 9;
        transform: rotate(180deg);

        @media screen and (max-width: 1000px) {
            top: 45%;
        }

        @media screen and (max-width: 769px) {
            display: none !important;
        }
    }

    .slick-prev:before {
        content: '\279C';
        color: #0d77bd;
        font-size: 65px;
    }

    .slick-next {
        right: 8%;
        width: 65px;
        height: 65px;

        @media screen and (max-width: 1000px) {
            top: 45%;
        }

        @media screen and (max-width: 769px) {
            display: none !important;
        }
    }

    .slick-next:before {
        content: '\279C';
        color: #0d77bd;
        font-size: 65px;
    }
    
    .bannerSlider {
        font-family: 'Playfair Display', serif;
        text-align: left;
        position: relative;
        height: 100vh;

        h3 {
            font-size: 80.76px;
            line-height: 84.6px;
            color: #0d77bd;

            @media screen and (max-width: 1750px) {
                font-size: 72.76px;
                line-height: 64.6px;
            }

            @media screen and (max-width: 1650px) {
                font-size: 66.76px;
                line-height: 50.6px;
            }

            @media screen and (max-width: 1200px) {
                font-size: 45px;
                line-height: 15px;
            }

            span {
                display: block;
                line-height: 51.84px;
                color: #6d6f72;
                margin: 50px 0 0 180px;
            }
        }

        .telephone-text {
            width: 51%;
            float: left;
        }

        .telephone {
            width: 42%;
            float: right;
            margin-right: 7%;
            display: block;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .bannerSlider:focus {
        outline: none;
    }

    .slider1 {
        // background-image: url(../../assets/images/slider-bg1.jpg);
        // background-repeat: no-repeat;
        // background-position: left bottom;
        // box-sizing: border-box;
        padding: 0;
        // background-size: 100%;

        .videoBgContainer {
            position: relative;
            height: 100vh;
            overflow: hidden;
            // margin-bottom: 500px;
            width: 100%;

            @media screen and (max-width: 1300px) {
                background-image: url(../../assets/images/slider-bg1.jpg);
                background-repeat: no-repeat;
                background-position: left bottom;
                box-sizing: border-box;
                padding: 0;
                background-size: 200%;
            }

            @media screen and (max-width: 768px) {
                background-size: 300%;
            }

            @media screen and (max-width: 740px) and (min-width: 550px) {
                background-size: 200%;
            }

            @media screen and (max-width: 400px) {
                background-size: 500%;
            }

            video {
                @media screen and (max-width: 1300px) {
                    display: none;
                }
            }
        }

        .bannerVideo {
            width: 100%;
            height: auto;
        }

        .bannerContent {
            width: 100%;

            h3 {
                // margin-left: 15%;
                // position: absolute;
                // transition: 1.5s ease;
                // width: 84%;
                // top: 35vh;
                margin-left:15%;
                position:absolute;
                left: 7%;
                transition:1.5s ease;
                width:84%;
                top: 35vh;

                @media screen and (max-width: 1750px) {
                    margin-left: 12%;
                }

                // @media screen and (max-width: 1300px) {
                //     top: 40vh;
                // }

                @media screen and (max-width: 1200px) {
                    margin-left: 15%;
                }

                @media screen and (max-width: 1000px) {
                    top: 40%;
                    margin-left: 5%;
                }

                @media screen and (max-width: 769px) {
                    top: 40%;
                    margin-left: 10%;
                    font-size: 40px;
                    line-height: 50px;
                }

                @media screen and (max-width: 600px) {
                    margin-left: 7%;
                }

                @media screen and (max-width: 500px) {
                    font-size: 30px;
                    line-height: 35px;
                }
            }

            span{
                display:block;
                line-height:51.84px;
                color:#6d6f72;
                margin:50px 0 0 180px;

                @media screen and (max-width:1200px){
                    line-height:20.84px;
                    margin:50px 0 0;
                }

                @media screen and (max-width:769px){
                    margin-top:0;
                    line-height:50px;
                }

                @media screen and (max-width:500px){
                    line-height:35px;
                }
            }

            // #h3-right {
            //     top: 49vh;
            //     display: block;
            //     line-height: 51.84px;
            //     color: #6d6f72;
            //     transition: 2s ease;
            //     // margin: 50px 0 0 180px;

            //     @media screen and (max-width: 1300px) {
            //         top: 54vh;
            //     }
                
            //     @media screen and (max-width: 1200px) {
            //         line-height: 20.84px;
            //         top: 44vh;
            //     }

            //     @media screen and (max-width: 769px) {
            //         line-height: 50px;
            //     }

            //     @media screen and (max-width: 500px) {
            //         line-height: 35px;
            //     }
            // }
        }
    }

    .slider2 {
        // background-image: url(../../assets/images/slider-bg2.jpg);
        background-repeat: no-repeat;
        background-position: center right 20%;
        background-size: 32%;
        box-sizing: border-box;
        padding: 35vh 0;

        @media screen and (max-width: 1000px) {
            background-position: center top 35%;
        }

        @media screen and (max-width: 769px) {
            background-size: 46%;
        }

        @media screen and (max-width: 600px) {
            background-size: 65%;
        }

        @media screen and (max-width: 500px) {
            background-size: 75%;
        }

        @media screen and (max-width: 400px) {
            background-size: 100%;
        }


        h3 {
            // position: absolute;
            // left: 14%;
            // top: 40%;

            margin-left: 11%;
            margin-top: 10vh;

            @media screen and (max-width: 1440px) {
                margin-top: 13vh;
            }

            @media screen and (max-width: 1025px) {
                margin-top: 14.5vh;
            }

            @media screen and (max-width: 1000px) {
                margin-left: 0;
                margin-top: 175px;
                text-align: center;
            }

            @media screen and (max-width: 813px) {
                margin-top: 150px;
            }

            @media screen and (max-width: 769px) {
                font-size: 40px;
                line-height: 50px;
                margin-top: 150px;
            }

            @media screen and (max-width: 500px) {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }

    .slider3 {
        // background-image: url(../../assets/images/slider-badge.png);
        background-repeat: no-repeat;
        background-position: top 35% center;
        box-sizing: border-box;
        padding: 35vh 0;

        h3 {
            text-align: center;
            margin-top: 150px;

            @media screen and (max-width: 769px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media screen and (max-width: 500px) {
                font-size: 30px;
                line-height: 35px;
            }
        }

        img {
            margin: 0 auto;
        }
    }
}

