.Privacy {
    padding: 150px 0 80px;
    border-bottom: 3px solid #22a6de;

    .privacyContent {
        width: 84%;
        float: left;
        margin: 1vh 0;
        position: absolute;
        z-index: 0;

        @media screen and (max-width: 1025px) {
            margin: 2vh 0;
        }

        .privacyContentLeft {
            width: 32%;
            float: left;
            background-image: url(../../assets/images/about-bg.png);
            background-repeat: no-repeat;
            position: relative;
            height: 74.5vh;

            @media screen and (max-width: 1025px) {
                width: 35%;
            }

            @media screen and (max-width: 769px) {
                width: 45%;
            }

            @media screen and (max-width: 650px) {
                width: 100%;
            }

            h2 {
                color: #22a4dd;
                font-size: 134px;
                line-height: 110px;
                text-transform: uppercase;
                font-family: 'Playfair Display', serif;
                // margin-bottom: 200px;
                margin-top: 1vh;
                // width: 26.3%;
                position: absolute;
                left: 0;
                transition: 0.5s ease;

                @media screen and (max-width: 1566px) {
                    font-size: 120px;
                }

                @media screen and (max-width: 1440px) {
                    font-size: 100px;
                }

                @media screen and (max-width: 1025px) {
                    font-size: 80px;
                }

                @media screen and (max-width: 900px) {
                    font-size: 55px;
                }

                // @media screen and (max-width: 650px) {
                //     font-size: 55px;
                // }
            }
        }

        .privacyContentRight {
            // position: absolute;
            // right: 15%;
            // top: 185px;
            // z-index: 0;
            width: 25%;
            float: right;
            margin-right: 8%;

            @media screen and (max-width: 1566px) {
                width: 27%;
                margin-right: 6%;
            }

            @media screen and (max-width: 1200px) {
                width: 29%;
                margin-right: 4%;
            }
    
            @media screen and (max-width: 1025px) {
                width: 33%;
            }

            @media screen and (max-width: 769px) {
                width: 45%;
            }

            @media screen and (max-width: 650px) {
                width: 84%;
            }
        }
    }

    .privacyFloatingContainer {
        display: block;
        width: 70%;
        margin-left: 30%;
        // margin-top: 12vh;
        transition: 1s ease;

        .privacyFloating {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // position: absolute;
            // top: 180px;
            // left: 26%;
            // width: 50%;
            position: relative;
            display: block;
            width: 100%;
            // margin: 0 auto;
            // margin-top: 100px;
            text-align: left;
            max-height: 60vh;
            min-height: 60vh;
            overflow-y: scroll;
            // -webkit-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // -moz-box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            // box-shadow: 0px 0px 25px 13px rgba(237,237,237,0.75);
            background-color: rgba(256,256,256,0.9);
            -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            -o-box-shadow: 0 30px 60px rgba(0, 0, 0, .08);
            box-shadow: 0 30px 60px rgba(0, 0, 0, .08);

            @media screen and (max-width: 650px) {
                background-color: rgba(256,256,256,0.7);
            }

            p {
                color: #616161;
                font-size: 20px;
                line-height: 40px;
                padding: 25px 45px;
                margin: 0;

                @media screen and (max-width: 650px) {
                    font-size: 15px;
                    line-height: 25px;
                    padding: 15px 10px;
                }
            }
        }
    }

    .privacyFloating::-webkit-scrollbar {
        width: 3px;
    }
    
    .privacyFloating::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: transparent;
    }
    
    .privacyFloating::-webkit-scrollbar-thumb {
        background-color: #22a4dd;
        padding-right: 5%;
    }
}