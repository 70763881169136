.Contact {
    width: 100%;
    display: block;
    float: left;
    margin: 50px 0;
    text-align: left;
    color: #00548b;

    p {
        margin-left: 100px;
        font-size: 21px;
        line-height: 35px;
        // word-wrap: break-word;

        @media screen and (max-width: 1200px) {
            font-size: 15px;
            line-height: 25px;
        }

        @media screen and (max-width: 1200px) {
            margin-left: 70px;
        }
    }

    a {
        font-size: 21px;
        line-height: 35px;
        text-decoration: none;
        color: #00548b;
        transition: 1s ease;

        @media screen and (max-width: 1200px) {
            font-size: 15px;
            line-height: 25px;
        }

        &:hover {
            text-decoration: underline;
            transition: 1s ease;
        }
    }

    .map {
        width: 45%;
        display: block;
        float: left;
        position: relative;
        padding-right: 11%;

        @media screen and (max-width: 1200px) {
            width: 40%;
            padding-right: 6%;
        }

        @media screen and (max-width: 769px) {
            width: 100%;
            margin: 30px 0;
        }
    }

    .map:before {
        content: '';
        background-image: url(../../assets/images/map.png);
        position: absolute;
        left: 0;
        width: 80px;
        height: 79px;
        background-size: 80px;

        @media screen and (max-width: 1200px) {
            width: 60px;
            height: 59px;
            background-size: 60px;
        }
    }

    .phone {
        width: 30%;
        display: block;
        float: left;
        position: relative;
        padding-right: 5%;

        @media screen and (max-width: 769px) {
            width: 100%;
            margin: 30px 0;
        }
    }

    .phone:before {
        content: '';
        background-image: url(../../assets/images/phone.png);
        position: absolute;
        left: 0;
        width: 80px;
        height: 80px;
        background-size: 80px;

        @media screen and (max-width: 1200px) {
            width: 60px;
            height: 60px;
            background-size: 60px;
        }
    }

    .mail {
        width: 23%;
        display: block;
        float: left;
        position: relative;

        @media screen and (max-width: 1200px) {
            width: 29%;
        }

        @media screen and (max-width: 769px) {
            width: 100%;
            margin: 30px 0;
        }

        p {
            line-height: 70px;

            @media screen and (max-width: 1200px) {
                line-height: 50px;
            }
        }
    }

    .mail:before {
        content: '';
        background-image: url(../../assets/images/mail.png);
        position: absolute;
        left: 0;
        width: 80px;
        height: 80px;
        background-size: 80px;

        @media screen and (max-width: 1200px) {
            width: 60px;
            height: 60px;
            background-size: 60px;
        }
    }
}